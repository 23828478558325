class TianjyLoading extends HTMLElement {
	constructor() {
		super();
		const m = this.attachShadow({mode: 'closed'});
		m.innerHTML = `
<style>
:host {
--spinner-size: 42px;
position: absolute;
z-index: 2000;
background-color: rgba(255, 255, 255, .9);
margin: 0;
top: 0;
right: 0;
bottom: 0;
left: 0;
transition: opacity .3s;
}
:host([dark]) {
background-color: rgba(0, 0, 0, .8);
}
:host([fullscreen]) {
position: fixed;
--spinner-size: 50px;
}
.spinner {
top: 50%;
margin-top: calc((0px - var(--spinner-size)) / 2);
width: 100%;
text-align: center;
position: absolute
}
.circular {
display: inline;
height: var(--spinner-size);
width: var(--spinner-size);
animation: loading-rotate 2s linear infinite
}
.path {
animation: loading-dash 1.5s ease-in-out infinite;
stroke-dasharray: 90, 150;
stroke-dashoffset: 0;
stroke: #409eff;
}
@keyframes loading-dash {
0% {
stroke-dasharray: 1, 200;
stroke-dashoffset: 0
}

50% {
stroke-dasharray: 90, 150;
stroke-dashoffset: -40px
}
to {
stroke-dasharray: 90, 150;
stroke-dashoffset: -120px
}
}


@keyframes loading-rotate {
to { transform: rotate(360deg) }
}
</style>
<div class="spinner">
<svg class="circular" viewBox="0 0 50 50">
<circle class="path"
cx="25"
cy="25"
r="20"
fill="none"
stroke-width="2"
stroke-linecap="round"
stroke="#409eff"
></circle>
</svg>
</div>
`;
	}

}
customElements.define('tianjy-loading', TianjyLoading);
